import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Enroll candidate",
    to: "/recruiter-chatbot/external-flow",
    icon: "mdi-account-plus",
    permissions: [
      {
        name: "recruiter-chatbot.external-flow",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Apply on behalf of candidate",
    to: "/recruiter-chatbot/applications",
    icon: "mdi-account-check",
    permissions: [
      {
        name: "recruiter-chatbot.applications",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Update candidate Recruiter Bot preferences",
    to: "/recruiter-chatbot/candidate-preferences",
    icon: "mdi-account-cog",
    permissions: [
      {
        name: "recruiter-chatbot.candidate-preferences",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Postpone notifications",
    to: "/recruiter-chatbot/postpone-notifications",
    icon: "mdi-calendar-clock",
    permissions: [
      {
        name: "recruiter-chatbot.postpone-notifications",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Mark candidate as not interested",
    to: "/recruiter-chatbot/mark-candidate-not-interested",
    icon: "mdi-account-cancel",
    permissions: [
      {
        name: "recruiter-chatbot.mark-candidate-not-interested",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Build screening questions URL",
    to: "/recruiter-chatbot/build-screening-questions-url",
    icon: "mdi-link",
    permissions: [
      {
        name: "recruiter-chatbot.build-screening-questions-url",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Update connection status",
    to: "/recruiter-chatbot/update-connection-status",
    icon: "mdi-account-switch",
    permissions: [
      {
        name: "recruiter-chatbot.update-connection-status",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "External sourcing request",
    to: "/recruiter-chatbot/external-sourcing-request",
    icon: "mdi-account-search",
    permissions: [
      {
        name: "recruiter-chatbot.external-sourcing-request",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Manual invite sourcing candidates",
    to: "/recruiter-chatbot/manual-invite-sourcing-candidates",
    icon: "mdi-account-multiple-plus",
    permissions: [
      {
        name: "recruiter-chatbot.manual-invite-sourcing-candidates",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Update CCG Email",
    to: "/recruiter-chatbot/ccg-emails",
    icon: "mdi-email-edit-outline",
    permissions: [
      {
        name: "recruiter-chatbot.ccg-emails",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Create verification request",
    to: "/recruiter-chatbot/verification-request",
    icon: "mdi-check-decagram-outline",
    permissions: [
      {
        name: "recruiter-chatbot.verification-request",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
